import React, { useState } from "react";
import SocialIcons from "../../Components/SocialIcon/SocialIcons";
import { Header, HeaderNav, Menu } from "../../Components/HeaderMain/Header";
import { Col, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./HeaderMain.scss";
import { useLocation } from "react-router-dom";

const SocialIconsData = [
  {
    color: "#fff",
    link: "https://www.facebook.com/ujjwaldental",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#fff",
    link: "https://www.instagram.com/ujjwaldentalcare?igsh=NHhkYTRqdGozbHRz",
    icon: "fab fa-instagram",
  },
  {
    color: "#fff",
    link: "https://youtube.com/",
    icon: "fab fa-youtube",
  },
];

const HeaderMain = (props) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* Header Start */}
      <Header
        topSpace={{ md: true }}
        type="reverse-scroll"
        className={`${currentPath !== "/" ? "homeless_header" : ""}`}
      >
        <HeaderNav
          theme="light"
          expand="lg"
          containerClass="!px-0"
          className="py-[0px] px-[100px] md:pr-[15px] md:pl-0 md:py-[10px] border-b border-[#ffffff70]"
          fluid="fluid"
        >
          <Col className="col-auto col-sm-2 col-lg-1 ps-lg-0 ">
            <Link
              aria-label="header logo"
              className="flex items-center justify-content-center"
              to="/"
            >
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/ujjwaldental/Header/logo.webp"
                  data-rjs="/assets/img/ujjwaldental/Header/logo.webp"
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/ujjwaldental/Header/logo.webp"
                  data-rjs="/assets/img/ujjwaldental/Header/logo.webp"
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/ujjwaldental/Header/logo.webp"
                  data-rjs="/assets/img/ujjwaldental/Header/logo.webp"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle
            className={`order-last md:ml-[25px] sm:ml-[17px] ${
              !open ? "collapsed" : ""
            }`}
            onClick={handleToggle}
          >
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse
            className="col-auto col-lg-7 menu-order px-lg-0"
            in={open}
          >
            <Menu {...props} handleClose={handleClose} />
          </Navbar.Collapse>
          <Col className="header_button col-auto col-lg-4 pe-0">
            <button
              className="book_button"
              onClick={() => {
                window.location.href = "https://wa.me/+919798133551";
              }}
            >
              Book An Appointment <i className="fa-brands fa-whatsapp"></i>
            </button>
            <div className="icons">
              <SocialIcons
                theme="social-icon-style-01"
                size="xs"
                iconColor="custom"
                className="justify-end"
                data={SocialIconsData}
              />
            </div>
          </Col>
        </HeaderNav>
      </Header>
      {/* Header End */}
    </>
  );
};

export default HeaderMain;
