import React from "react";
import { Container } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import './Banner.scss';

const Banner = (props) => {
  return (
    <>
      <div className="h-[50vh] md:flex md:items-center overflow-hidden relative sm:h-[35vh] md:h-[25vh]">
        <Parallax
          className="lg-no-parallax absolute top-[0px] left-0 w-full h-[100vh] bg-cover bg-no-repeat"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url('${props.url}')`,
          }}
        ></Parallax>
        <Container fluid className="relative">
          <div className="page_title">{props.name}</div>
        </Container>
      </div>
    </>
  );
};

export default Banner;
