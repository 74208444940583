import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./FooterMain.scss";
const FooterMain = () => {
  return (
    <div>
      {/* Footer Start */}
      <footer className="bg-[#202a2c] pt-[50px] py-[25px] main_footer">
        <Container>
          <div className="details_container row">
            <div className="col-6 left_detail">
              <div className="detail_title d-flex gap-5 align-items-center flex-wrap">
                <img
                  src="/assets/img/ujjwaldental/Footer/logo_footer.png"
                  width={70}
                  alt="logo"
                />
                Jamshedpur
              </div>
              <div className="logo mt-[25px]"></div>
              <div className="contact_details">
                <div className="address">
                  <strong>Address:</strong> <Link to="https://maps.app.goo.gl/UpqJrqHk4zHUwpAL8">
                   Ground Floor, Leading tower, Opp
                  Bajaj Showroom, New baradwari, Sakchi, Jamshedpur, India,
                  Jharkhand
                  </Link>
                </div>
                <div className="phone">
                  <strong>Phone:</strong> <Link to="tel:+919798133551"> +91 97981 33551</Link>
                </div>
                <div className="email">
                  <strong>Email:</strong> <Link to="mailto:drvishal.endo@gmail.com"> drvishal.endo@gmail.com</Link>
                </div>
              </div>
              <ul className="social_icons d-flex gap-2">
                <li>
                  <a
                    href="https://www.facebook.com/ujjwaldental/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/ujjwaldentalcare?igsh=NHhkYTRqdGozbHRz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href=" https://g.co/kgs/b6FhGGG">
                    <i className="fa-brands fa-google"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://wa.me/+919798133551"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-whatsapp"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://youtube.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                </li>
              </ul>
              <div className="working_title">Working Days</div>
              <div className="working_time">
                <div className="day_name">Mon - Sat</div>
                <div className="timings">09:30 AM - 5:00 PM</div>
              </div>
              <div className="working_time">
                <div className="day_name">Sun</div>
                <div className="timings">Closed</div>
              </div>
            </div>
            <div className="col-6 right_detail">
              <div className="detail_title d-flex gap-5 align-items-center flex-wrap">
                <img
                  src="/assets/img/ujjwaldental/Footer/logo_footer.png"
                  width={70}
                  alt="logo"
                />
                Chakulia{/* Testing  */ }
              </div>
              {/*<div className="logo mt-[25px]"></div>*/}
              <div className="contact_details">
                <div className="address">
                  <strong>Address:</strong> Chakulia
                </div>
                <div className="phone">
                  <strong>Phone:</strong> <Link to="tel:+919798133551"> +91 97981 33551</Link>
                </div>
                <div className="email">
                  <strong>Email:</strong> <Link to="mailto:drvishal.endo@gmail.com"> drvishal.endo@gmail.com</Link>
                </div>
              </div>
              <ul className="social_icons d-flex gap-2">
                <li>
                  <a
                    href="https://www.facebook.com/ujjwaldental/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/ujjwaldentalcare?igsh=NHhkYTRqdGozbHRz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href=" https://g.co/kgs/b6FhGGG">
                    <i className="fa-brands fa-google"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://wa.me/+919798133551"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-whatsapp"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://youtube.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                </li>
              </ul>
              <div className="working_title">Working Days</div>
              <div className="working_time">
                <div className="day_name">Mon - Fri</div>
                <div className="timings">09:30 AM - 5:00 PM</div>
              </div>
              <div className="working_time">
                <div className="day_name">Sun</div>
                <div className="timings">08:30 AM - 06:00 PM</div>
              </div>
              <div className="working_time">
                <div className="day_name">Sat</div>
                <div className="timings">Closed</div>
              </div>
            </div>
          </div>
        </Container>
      </footer>
      <div className="subFooter_container">
        <div className="left_footer">
          © 2023 UJJWALDENTAL.COM | All Rights Reserved.
        </div>
        <div className="right_footer d-flex gap-1">
          Developed by :{" "}
          <img src="/assets/img/teckat-logo.png" alt="logo" width={30} />{" "}
          <Link to="https://teckat.com/">Teckat.com</Link>
        </div>
      </div>
      {/* Footer End */}
    </div>
  );
};

export default FooterMain;
