import React, { useEffect, useState } from "react";
import Banner from "../../Components/Banner/Banner";
import { Container } from "react-bootstrap";
import { serviceData } from "./ServiceData";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./ServiceDetails.scss";

const ServiceDetails = () => {
  const id = useParams();
  const [allServices, setAllServices] = useState([]);
  const [serviceCategory, setServiceCategory] = useState();

  useEffect(() => {
    setAllServices([
      ...serviceData.generalDentalServices.subCategory,
      ...serviceData.orthodontics.subCategory,
      ...serviceData.restorativeDentalMaterial.subCategory,
    ]);
  }, []);

  useEffect(() => {
    setServiceCategory(
      allServices.filter((service) => service.id === id.id)[0]
    );
  }, [id.id, allServices, setServiceCategory]);

  return (
    <>
      {serviceCategory !== null && serviceCategory !== undefined && (
        <>
          <Banner
            name={serviceCategory.name}
            url="/assets/img/ujjwaldental/Services/service-detail-banner.webp"
          />
          <Container>
            <div className="images_container row py-[50px]">
              {serviceCategory.subCategoryImage.map((item, j) => {
                return (
                  <div className="images col-4" key={j}>
                    <img src={item} alt="service" />
                  </div>
                );
              })}
            </div>

            <div className="service_details_container">
              <div className="name">{serviceCategory.name}</div>
              <div className="title">{serviceCategory.title}</div>
              <div className="description">{serviceCategory.description}</div>
              <div className="button_container">
                <Link to="/contact"><button className="service_btn">Book An Appointment</button></Link>
              </div>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default ServiceDetails;
