const HeaderDataAlpha = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About",
    dropdown: [
      {
        title: "About Hospital",
        link: "/about/hospital",
      },
      {
        title: "About Doctors",
        link: "/about/doctors",
      },
    ],
  },

  {
    title: "Services",
    link: "/services",
    megamenu: [
      {
        title: "General Dental Services",
        dropdown: [
          {
            title: "Teeth Cleaning",
            link: "/service-details/1001",
          },
          {
            title: "Tooth Extractions",
            link: "/services",
          },
          {
            title: "Wisdom Tooth Removal",
            link: "/services",
          },
          {
            title: "Children Dentistry",
            link: "/services",
          },
        ],
      },
      {
        title: "Orthodontics",
        dropdown: [
          {
            title: "Braces Procedures",
            link: "/services",
          },
          {
            title: "Invisalign",
            link: "/services",
          },
          {
            title: "Oral Health",
            link: "/services",
          },
          {
            title: "Bad Breath",
            link: "/services",
          },
          {
            title: "Gum and Oral Health",
            link: "/services",
          },
          {
            title: "Sealants",
            link: "/services",
          },
          {
            title: "Tooth Injury",
            link: "/services",
          },
          {
            title: "Tooth Decay",
            link: "/services",
          },
        ],
      },
      {
        title: "Restorative Dental Material Types",
        dropdown: [
          {
            title: "Inlays, On-lays & Veneers",
            link: "/services",
          },
          {
            title: "Dental Crowns and Bridges",
            link: "/services",
          },
          {
            title: "Root Canal",
            link: "/services",
          },
          {
            title: "Dental Fillings (Composite and Amalgam)",
            link: "/services",
          },
        ],
      },
    ],
  },
  {
    title: "Academics",
    dropdown: [
      {
        title: "Courses",
        link: "/academic/courses",
      },
    ],
  },
  {
    title: "Gallery",
    link: "/gallery",
  },
  {
    title: "Contact Us",
    link: "/contact",
  },
  {
    title: "Location",
    dropdown: [
      {
        title: "Jamshedpur",
        link: "https://maps.app.goo.gl/UpqJrqHk4zHUwpAL8",
      },
      {
        title: "Chakulia",
        link: "#",
      },
    ],
  },
];

export default HeaderDataAlpha;
