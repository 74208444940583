export const serviceData = {
  generalDentalServices: {
    id: 1,
    name: "General Dental Services",
    subCategory: [
      {
        id: "1001",
        img: "/assets/img/ujjwaldental/Services/General-dental-services/teeth-cleaning.webp",
        name: "Teeth Cleaning",
        categoryName: "General Dental Services",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [
          "/assets/img/ujjwaldental/Services/General-dental-services/teeth-cleaning1.webp",
          "/assets/img/ujjwaldental/Services/General-dental-services/teeth-cleaning2.webp",
          "/assets/img/ujjwaldental/Services/General-dental-services/teeth-cleaning3.webp",
        ],
        description:
          "lorem Nulla dolor velit adipisicing duis excepteur esse in duis nostrud occaecat mollit incididunt deserunt sunt. Ut ut sunt laborum ex occaeca",
      },
      {
        id: "1002",
        img: "/assets/img/ujjwaldental/Services/General-dental-services/teeth-extraction.webp",
        name: "Tooth Extractions",
        categoryName: "General Dental Services",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description:
          "lorem Nulla dolor velit adipisicing duis excepteur esse in duis nostrud occaecat mollit incididunt deserunt sunt. Ut ut sunt laborum ex occaeca",
      },
      {
        id: "1003",
        img: "/assets/img/ujjwaldental/Services/General-dental-services/wisdom-tooth-removal.webp",
        name: "Wisdom Tooth Removal",
        categoryName: "General Dental Services",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description:
          "lorem Nulla dolor velit adipisicing duis excepteur esse in duis nostrud occaecat mollit incididunt deserunt sunt. Ut ut sunt laborum ex occaeca",
      },
      {
        id: "1004",
        img: "/assets/img/ujjwaldental/Services/General-dental-services/children-dentistry.webp",
        name: "Children Dentistry",
        categoryName: "General Dental Services",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description:
          "lorem Nulla dolor velit adipisicing duis excepteur esse in duis nostrud occaecat mollit incididunt deserunt sunt. Ut ut sunt laborum ex occaeca",
      },
    ],
  },
  orthodontics: {
    id: 2,
    name: "Orthodontics",
    subCategory: [
      {
        id: "2001",
        img: "/assets/img/ujjwaldental/Services/Orthodontics/braces-procedures.webp",
        name: "Braces Procedures",
        categoryName: "Orthodontics",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description: "",
      },
      {
        id: "2002",
        img: "/assets/img/ujjwaldental/Services/Orthodontics/Invisalign.webp",
        name: "Invisalign",
        categoryName: "Orthodontics",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description: "",
      },
      {
        id: "2003",
        img: "/assets/img/ujjwaldental/Services/Orthodontics/oral-health.webp",
        name: "Oral Health",
        categoryName: "Orthodontics",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description: "",
      },
      {
        id: "2004",
        img: "/assets/img/ujjwaldental/Services/Orthodontics/bad-breathe.webp",
        name: "Bad Breath",
        categoryName: "Orthodontics",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description: "",
      },
      {
        id: "2005",
        img: "/assets/img/ujjwaldental/Services/Orthodontics/gum-and-oral-health.webp",
        name: "Gum and Oral Health",
        categoryName: "Orthodontics",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description: "",
      },
      {
        id: "2006",
        img: "/assets/img/ujjwaldental/Services/Orthodontics/sealants.webp",
        name: "Sealants",
        categoryName: "Orthodontics",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description: "",
      },
      {
        id: "2007",
        img: "/assets/img/ujjwaldental/Services/Orthodontics/tooth-injury.webp",
        name: "Tooth Injury",
        categoryName: "Orthodontics",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description: "",
      },
      {
        id: "2008",
        img: "/assets/img/ujjwaldental/Services/Orthodontics/tooth-decay.webp",
        name: "Tooth Decay",
        categoryName: "Orthodontics",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description: "",
      },
    ],
  },
  restorativeDentalMaterial: {
    id: 3,
    name: "Restorative Dental Material",
    subCategory: [
      {
        id: "3001",
        img: "/assets/img/ujjwaldental/Services/Restorative-Dental-Material/inlays-on-lays.webp",
        name: "Inlays, On-lays & Veneers",
        categoryName: "Restorative Dental Material",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description: "",
      },
      {
        id: "3002",
        img: "/assets/img/ujjwaldental/Services/Restorative-Dental-Material/dental-crowns-and-bridges.webp",
        name: "Dental Crowns and Bridges",
        categoryName: "Restorative Dental Material",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description: "",
      },
      {
        id: "3003",
        img: "/assets/img/ujjwaldental/Services/Restorative-Dental-Material/root-canal.webp",
        name: "Root Canal",
        categoryName: "Restorative Dental Material",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description: "",
      },
      {
        id: "3004",
        img: "/assets/img/ujjwaldental/Services/Restorative-Dental-Material/dental-fillings.webp",
        name: "Dental Fillings (Composite and Amalgam)",
        categoryName: "Restorative Dental Material",
        title: "lorem Creativity is nothing but a mind set free",
        subCategoryImage: [],
        description: "",
      },
    ],
  },
};
